import { defineStore } from 'pinia'
import type { AppStoreState } from './helper'
import { fetchQueryMineAppsAPI } from '@/api/appStore'
import { fetchQueryMessageList } from '@/api/message'
import { store } from '@/store'

export const useAppCatStore = defineStore('app-cat-store', {
  state: (): AppStoreState => ({
    catId: 0,
    mineApps: [],
    messageLists:[]
  }),

  actions: {
    setCatId(catId: number) {
      this.catId = catId
    },

    async queryMineApps() {
      const res = await fetchQueryMineAppsAPI()
      this.mineApps = res?.data?.rows || []
    },

    async queryMessageList(){
      const res = await fetchQueryMessageList()
      this.messageLists = res?.data || []
    }
  },
})

export function useAppCatStoreWithOut() {
  return useAppStore(store)
}
