import { get, post } from '@/utils/request'

/* 查询我的消息列表 */
export function fetchQueryMessageList<T>(): Promise<T> {
  return get<T>({
    url: '/messageCenter/list',
  })
}

/* 标记全部已读 */
export function fetchMessageAllRead<T>(): Promise<T> {
  return post<T>({
    url: '/messageCenter/allRead',
  })
}

/* 使用Sse来更新未读消息 */
export function fetchQueryUnreadMessages<T>(): Promise<T> {
  return get<T>({
    url: '/messageCenter/unread',
  })
}

const baseUrl = `${import.meta.env.VITE_GLOB_API_URL}`
export function fetchUnreadMessages(userId: number): EventSource {
  return new EventSource(`${baseUrl}/messageCenter/unread/${userId}`);
}
