import { ss } from '@/utils/storage'

const LOCAL_NAME = 'chatStorage'

export function defaultState(): Chat.ChatState {
  return {
    active: 0,
    usingContext: true,
    usingVoice: false,
    usingNetwork: false,
    groupList: [],
    chatList: [],
    groupKeyWord: '',
    baseConfig: null,
    modelList: [],
    chatPreList: [],
    pluginList: [],
    usingVoiceType: 'alloy',
    usingVoiceAutoplay: false,
    loadingGetChatList: false,
    loadingGetChatGroup: false,
    selectAppId: 0,
    cacheAllModelList: [],
    promptList: {}
  }
}

export function getLocalState(): Chat.ChatState {
  const localState = ss.get(LOCAL_NAME)
  return { ...defaultState(), ...localState }
}

export function setLocalState({ active, usingVoice, usingVoiceAutoplay, selectAppId, usingVoiceType }: Chat.ChatState) {
  ss.set(LOCAL_NAME, { ...ss.get(LOCAL_NAME), active, usingVoice, usingVoiceType, usingVoiceAutoplay, selectAppId })
}

export function formatChatPre(data: any): any {
  return data.map((item: any) => {
    const { name, childList, id } = item
    return {
      label: name,
      value: id,
      children: childList.map((t: any) => {
        return {
          label: t.title,
          value: t.prompt,
        }
      }),
    }
  })
}
