<script setup lang='ts'>
import { NModal, NScrollbar, useMessage, NEmpty } from 'naive-ui'
import { computed, ref } from 'vue'
import { utcToShanghaiTime } from '@/utils/utcformatTimes/utcformatTime'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { useAppStore, useGlobalStoreWithOut, useAppCatStore } from '@/store'
import { fetchMessageAllRead } from '@/api/message'
import { SvgIcon } from '@/components/common'
const ms = useMessage()
const unread = ref(0)
const appCatStore = useAppCatStore()
const messageList = computed(() => appCatStore.messageLists)
defineProps<Props>()
interface Props {
  visible: boolean
}

const { isMobile } = useBasicLayout()
const appStore = useAppStore()
const useGlobalStore = useGlobalStoreWithOut()
const loading = ref(true)
const allowedClass = ref(false)
async function openDrawerAfter() {
  await appCatStore.queryMessageList()
  unread.value =  messageList.value.filter(item => item?.messageStatus === 0).length;
  if(!unread.value){
    allowedClass.value = true
    return
  }

  loading.value = false
}

function handleCloseDialog() {
  loading.value = true
}

async function handleAllRead(){
  if(!unread.value){
    return
  }
  await fetchMessageAllRead()
}

function handleClose() {
  useGlobalStore.updateMessageCenterDialog(false)
}
</script>

<template>
  <NModal :show="visible" :class="isMobile ? 'w-[90%] h-[70%]' : 'h-[610px] w-[880px]'" class="noticeDialog  flex flex-col" :on-after-enter="openDrawerAfter"
    transform-origin="mouse" :close-on-esc="true" :on-after-leave="handleCloseDialog" :style="{
      backgroundColor: appStore.theme === 'dark' ? '#131314' : '#fff', position: 'fixed', top: '100px', left: '50%', transform: 'translateX(-50%)'
    }">
    <div class="px-[15px] rounded-md relative">
      <div class="h-[70px] flex justify-between" :class="isMobile ? '' : ''">
        <div class="flex mt-4 ml-3">
          <span class="text-[18px] text-primary font-medium">{{ '消息中心' }}</span>
        </div>
        <div
          class="absolute right-[12px] top-[12px] rounded-sm  transition-all cursor-pointer p-[2px] flex">
          <span class="flex mr-3 items-center text-[#585a5a]" :class="allowedClass ? 'cursor-not-allowed hover:text-[#bec0bf]': 'cursor-pointer' "  @click="handleAllRead">
            <SvgIcon icon="ant-design:clear-outlined" class="text-[16px]"  />
            全部已读
          </span>
          <span class="hover:bg-[#e8e8e8]" >
            <SvgIcon icon="ic:twotone-close" class="text-[20px]" @click="handleClose" />
          </span>
        </div>
      </div>
      <div class="body flex">
        <div class="sider w-[154px]" :class="isMobile ? 'hidden' : ''">
          <div class="active">全部消息 <span v-if="unread" >({{ unread }}条未读)</span> </div>
        </div>
        <div class="content flex-1 "  :class="isMobile ? 'h-[420px]' : 'h-[500px]'">
          <NScrollbar>
            <ul class="notification-list overflow-hidden " >
              <li v-for="item in messageList" class="hover:bg-[#fafafa]">
                <div class="notification-item">
                  <div class="avatar">
                    <img style="width: 32px; min-width: 32px; height: 32px; border-radius: 16px;"
                      src="../../assets/logo-s.png" alt="">
                  </div>
                  <div class="NotificationItem_content">
                    <p v-html="item.messageText" ></p>
                    <div class="time">{{ utcToShanghaiTime(item?.createdAt) }}</div>
                  </div>
                </div>
              </li>
            </ul>
            <div v-if="!messageList.length"  class="flex justify-center items-center h-[300px]">
              <div class="flex flex-col items-center">
                <img style="width: 140px;" src="../../assets/no_message.png" alt="">
                <span class="text-[#bec0bf]" >暂无消息</span>
              </div>
            </div>
          </NScrollbar>
        </div>
      </div>
    </div>
  </NModal>
</template>

<style scoped>
:deep(.n-card__content) {
  padding: 0 !important;
}

.body{
  height: 100%;
}

.body .sider{
  border-right: 1px solid #eff0f0;
}
.body .sider div {
  /* padding: 8px 12px; */
  height: 32px;
  border-radius: 6px;
  min-width: 132px;
  margin-right: 12px;
  margin-top: 6px;
  line-height: 32px;
  padding-left: 10px;
  cursor: pointer;
  
}

.content{
  overflow-y: auto;
}

.body .sider .active {
  background-color: #eff0f0;
}

.notification-list .notification-item:first-child{
  /* border-top: none; */
}

.keywordMessage{
  color: #000;
}

.notification-item {
  display: flex;
  align-items: top;
  background-color: var(--yq-bg-primary);
  transition: background-color .3s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  word-break: break-word;
  position: relative;
  padding: 16px 12px;
 
}

.NotificationItem_content {
  margin-left: 12px;
  font-size: 14px;
  word-wrap: break-word;
  color: #8a8f8d;
  overflow: hidden;
  width: 100%;

  p {
    margin-bottom: 0;
    max-width: 534px;
  }

  .time {
    font-size: 12px;
    color: #8a8f8d;
  }
}



.my {
  background-color: #dee8ff;
  color: #0057ff;
  --n-border: 1px solid transparent !important;
  font-weight: bold !important;

  &:hover {
    background-color: #dee8ff !important;
    color: #0057ff !important;
    --n-border-hover: 1px solid #bfd2ff !important;
  }
}

.tips {
  background-color: #fff;
  color: #000;
  --n-border: 1px solid transparent !important;
  font-weight: bold !important;

  &:hover {
    background-color: #dee8ff !important;
    color: #0057ff !important;
    --n-border-hover: 1px solid #bfd2ff !important;
  }
}
</style>
